import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './logo.png'; // Ensure you have the correct path to your logo file
import './App.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import backgroundImage from './background.jpeg'; // Ensure you have the correct path to your background image
import About from './About'; // Import the About component
import backgroundVideo from './background.mp4'; // Add your video file

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCwCNrR6wlLGv0w8gd0qvINB5sdNWFTPV8",
  authDomain: "ocean-orbiters.firebaseapp.com",
  projectId: "ocean-orbiters",
  storageBucket: "ocean-orbiters.appspot.com",
  messagingSenderId: "627012628872",
  appId: "1:627012628872:web:74589a7390e58a7e6e0a3d",
  measurementId: "G-C05D10M9XS"
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

const db = firebase.firestore();

function App() {
  const [email, setEmail] = useState('');
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((error) => {
        console.log('Error trying to play the video:', error);
      });

      // Fallback for interaction
      const handleUserInteraction = () => {
        video.play().catch((error) => {
          console.log('Error trying to play the video on interaction:', error);
        });
      };

      document.addEventListener('click', handleUserInteraction);
      document.addEventListener('touchstart', handleUserInteraction);

      return () => {
        document.removeEventListener('click', handleUserInteraction);
        document.removeEventListener('touchstart', handleUserInteraction);
      };
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const emailRef = db.collection('emails');
      const query = emailRef.where('email', '==', email);
      const snapshot = await query.get();

      if (snapshot.empty) {
        const signupCountSnapshot = await emailRef.get();
        const signupNumber = signupCountSnapshot.size + 1;

        await emailRef.add({ email, signupNumber });
        alert('Thanks for joining our mailing list!');
        setEmail(''); // Clear the input field

        // Send a message to the Discord server
        const webhookUrl = 'https://discord.com/api/webhooks/1246752868589899796/2EF6Ihwx7VvtvFTYkC8SDDLBTy2oTWgQoZrPcrSCKYPMd8ApSAWH17WMvlJvaOSZ_0V9';
        const message = {
          content: `${signupNumber}\n${email}`
        };

        await fetch(webhookUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(message)
        });
      } else {
        alert('Email already exists!');
      }
    } catch (error) {
      alert('Error checking email: ' + error.message);
    }
  };


  return (
    <Router>
      <div className="App">
        <header className="App-header fade-in">
          <nav className="navbar">
            <Link to="/">
              <img src={logo} className="navbar-logo" alt="logo" />
            </Link>
            <ul>
              <li><Link to="/about">About</Link></li>
              <li><a href="https://gofund.me/9fc404b8" target="_blank" rel="noopener noreferrer">Donate</a></li>
              <li><Link to="#contact">Contact</Link></li>
            </ul>
          </nav>
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/" element={
              <>
                <div className="background-image" style={{ backgroundImage: `url(${backgroundImage})` }}>
                  <div className="overlay">
                    <h1>Ocean Orbiters</h1>
                    <p>Using drones to clean beaches and protect marine life!</p>
                    <form className="signup-form" onSubmit={handleSubmit}>
                      <input
                        type="email"
                        placeholder="Email Address"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <button type="submit" className="pulse">Join Our Mailing List!</button>
                    </form>
                  </div>
                </div>
                <section className="stats-section fade-in">
                  <video 
                    ref={videoRef} 
                    autoPlay 
                    loop 
                    muted 
                    playsInline
                    onLoadedData={() => videoRef.current.play()}
                    onCanPlayThrough={() => videoRef.current.play()}
                  >
                    <source src={backgroundVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div className="overlay">
                    <h2>Our Vision...</h2>
                    <ul>
                      <li>5 tons of trash removed</li>
                      <li>3 beaches cleaned</li>
                      <li>20 volunteers engaged</li>
                    </ul>
                  </div>
                </section>
                <section className="new-section fade-in">
                  <h2>Technology Roadmap</h2>
                  <p>1. Develop and refine our advanced gripper technology for optimal trash collection.</p>
                  <p>2. Use machine learning to analyze environmental data and identify high-traffic trash zones on California beaches.</p>
                  <p>3. Implement a fleet of autonomous drones equipped with gripper technology to systematically clean identified areas.</p>
                </section>
              </>
            } />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
