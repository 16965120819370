import React from 'react';
import './App.css';
import './Animations.css';
import founderChrisImage from './chris.jpeg';
import founderTylerImage from './tyler.jpeg';
import founderKyanImage from './kyan.jpeg';
import founderAriImage from './chris.jpeg';
function About() {
  return (
    <div className="about">
      <header className="App-header">
        <section className="about-section fade-in">
          <h1>The Mission</h1>
          <div id="about">
            <p>We clean beaches and safeguard marine ecosystems by leveraging recent advances in AI, robotics, and autonomous drones.</p>
            <p>We engage with dedicated volunteers and partner with local and state governments to save marine life, reduce plastics in our ocean, and protect our planet.</p>
            <p>We enable our mission through our technology: data acquisition, training, and deployment roadmap — leading to large-scale impact.</p>
          </div>
        </section>
        <section className="founder-section fade-in">
          <h2>Meet Our Team</h2>
          <div className="founder-container">
            <div className="founder-details">
              <p className="founder-name">Christopher Luey</p>
              <img src={founderChrisImage} className="founder-image" alt="Founder" />
              <p className="founder-description">
                <span className="degree-name">B.S. Mechanical Engineering</span> (2025)<br />
                <span className="school-name">Northwestern University</span><br />
                Engineer @ Tesla, Ex NASA Engineer
              </p>
            </div>
            <div className="founder-details">
              <p className="founder-name">Tyler Jang</p>
              <img src={founderTylerImage} className="founder-image" alt="Founder" />
              <p className="founder-description">
                <span className="degree-name">B.S. Business Economics</span> (2027)<br />
                <span className="school-name">University of Chicago</span>
              </p>
            </div>
            <div className="founder-details">
              <p className="founder-name">Kyan Shipalik</p>
              <img src={founderKyanImage} className="founder-image" alt="Founder" />
              <p className="founder-description">
                <span className="degree-name">B.S. Mechanical Engineering</span> (2026)<br />
                <span className="school-name">Northwestern University</span>
              </p>
            </div>
            <div className="founder-details">
              <p className="founder-name">Ari Kapoor</p>
              <img src={founderAriImage} className="founder-image" alt="Founder" />
              <p className="founder-description">
                <span className="degree-name">B.S. Computer Science</span> (2025)<br />
                <span className="school-name">Carnegie Mellon University</span><br />
                SWE @ Gusto, Ex Silicon Valley Bank, Walmart
              </p>
            </div>
          </div>
        </section>


        <section className="founder-section fade-in">
          <h2>Meet Our Advisors</h2>
          <div className="founder-container">
            <div className="founder-details">
              <p className="founder-name">Kenar Vyas</p>
              <p className="founder-description">
                <span className="degree-name">B.S. Computer Science</span> (2023)<br />
                <span className="school-name">Princeton University</span><br />
                Autopilot @ Tesla
              </p>
            </div>
            <div className="founder-details">
              <p className="founder-name">Joseph Liu</p>
              <p className="founder-description">
                <span className="degree-name">B.S. Computer Science</span> (2025)<br />
                <span className="school-name">University of Southern California</span><br />
                NLP Researcher @ USC
              </p>
            </div>
            <div className="founder-details">
              <p className="founder-name">Shane Deng</p>
              <p className="founder-description">
                <span className="degree-name">PhD in Robotics</span> (2027)<br />
                <span className="school-name">Northwestern University</span><br/>
                M.S. at Carnegie Mellon University

              </p>
            </div>
            <div className="founder-details">
              <p className="founder-name">Vispi Karakaria</p>
              <p className="founder-description">
                <span className="degree-name">PhD in Machine Learning</span> (2026)<br />
                <span className="school-name">Northwestern University</span><br/>
                Machine Learning @ Ansys
              </p>
            </div>
          </div>
        </section>
      </header>
    </div>
  );
}

export default About;
